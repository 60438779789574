import { LinkRoot as MenuLink } from '@bluheadless/ui/src/molecules/menu/menu-first-level.styled'
import { ButtonStyled as WishlistIcon } from '@bluheadless/ui/src/molecules/mini-wish-list/mini-wish-list.styled'
import { ButtonStyled as CartIcon } from '@bluheadless/ui/src/molecules/minicart/minicart.styled'
import { SvgIcon as SearchIcon } from '@bluheadless/ui/src/molecules/search/search.styled'
import { LogoRoot } from '@bluheadless/ui/src/organisms/header/logo.styled'
import { HamburgerButton } from '@bluheadless/ui/src/organisms/sidebar-navigation/sidebar-navigation.styled'
import { css, styled } from '@mui/system'
import { UNPINNED } from './constants'
import { Root as StyledRoot } from './sticky-headroom.styled'

const overlapStyles = (color = 'var(--color-secondary)') => css`
	background-color: transparent;
	--transition-duration: 0.2s;
	${LogoRoot} {
		svg {
			transition: color var(--transition);
			color: ${color};
		}
	}
	${MenuLink} {
		transition: color var(--transition);
		.MuiLink-root {
			transition: none;
		}
		color: ${color};
	}
	${SearchIcon} {
		transition: color var(--transition);
		color: ${color};
	}
	${WishlistIcon} {
		.MuiBadge-root > .MuiSvgIcon-root {
			transition: color var(--transition);
			color: ${color};
		}
	}
	${CartIcon} {
		.MuiBadge-root > .MuiSvgIcon-root {
			transition: color var(--transition);
			color: ${color};
		}
	}
	${HamburgerButton} {
		> .MuiSvgIcon-root {
			transition: color var(--transition);
			color: ${color};
		}
		.MuiBadge-root > .MuiSvgIcon-root {
			transition: color var(--transition);
			color: ${color};
		}
	}
`

export const Root = styled(StyledRoot)`
	${({
		mode,
		overlap,
		theme: {
			palette: {
				background: { header },
			},
		},
		hideOnScrollDown,
		color,
		// theme,
	}) =>
		!hideOnScrollDown && overlap
			? overlapStyles(color)
			: /* mode === PINNED || */ mode === UNPINNED
			? overlapStyles() // pinnedStyles({ theme })
			: overlap
			? overlapStyles()
			: `background-color: ${header};
					${MenuLink}{
						color: var(--color-primary);
					}
				`};
	:hover {
		${({ overlap, hideOnScrollDown, color }) =>
			!hideOnScrollDown && overlap ? overlapStyles(color) : overlap ? overlapStyles() : ''}
	}
`
