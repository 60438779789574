import { styled } from '@mui/system'
import IconHeadArrowDown from '../../atoms/icons/arrowhead-down-icon'
import Typography from '../../atoms/typography'
import BHLink from '../link'

const shouldForwardProp = (prop) => !['columns', 'toggleDropdown', 'routeChanging', 'iconDropdownProps'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: ${({ columns }) => `repeat(${columns}, max-content)`};
	grid-gap: var(--spacing-5);
	pointer-events: ${({ routeChanging }) => (routeChanging ? 'none' : 'auto')};
	justify-content: center;
`

const NavItem = styled(Typography)`
	display: flex;
	flex: 1 0 auto;
	align-items: center;
	color: var(--color-primary);

	> .MuiSvgIcon-root {
		transition: transform 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
		color: ${({ theme }) => theme.palette.grey.main};
	}
	&:hover {
		color: ${({ theme }) => theme.palette.grey.main};
	}
	&.nav-dropdown-opened {
		color: ${({ theme }) => theme.palette.grey.main};
		.MuiSvgIcon-root {
			display: block;
		}
		a:after {
			background-color: ${({ theme }) => theme.palette.grey.main};
		}
	}
	&.nav-active {
		a:after {
			width: 100%;
		}
	}
`

const LinkRoot = styled('span')`
	display: flex;
	align-items: center;
	position: relative;
	> .MuiSvgIcon-root {
		display: none;
		position: absolute;
		bottom: -12px;
		right: 0;
		left: 0;
		margin: auto;
		color: rgba(255, 255, 255, 0.7);
	}
`

const Link = styled(BHLink, { shouldForwardProp })`
	color: inherit;
	cursor: pointer;
	display: inline-block;
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	${({ theme }) => theme.typography.button};
	padding: var(--spacing-1) 0;
	:has(.RichContent-Html-Root) {
		.RichContent-Html-Root > * {
			padding: var(--spacing-1) calc(var(--spacing-1) * 1.5);
			border-radius: var(--button-border-radius);
		}
	}
	:not(:has(.RichContent-Html-Root)) {
		border-radius: var(--button-border-radius);
	}
	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 1px;
		margin-top: -1px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}
`

const IconDropdown = styled(IconHeadArrowDown)`
	cursor: pointer;
`

export { IconDropdown, Link, LinkRoot, NavItem, NavMenu }
