import { Root } from './sticky-headroom-custom.styled'
import { bool, func, node, number, object } from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
/**
 * Forked from https://github.com/digitalfabrik/react-sticky-headroom
 */

import {
	UPWARDS,
	DOWNWARDS,
	UNPINNED,
	PINNED,
	STATIC,
	NO_TRANSITION,
	NORMAL_TRANSITION,
	PINNED_TO_STATIC,
} from './constants'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'

const StickyHeadroom = ({
	pinStart,
	zIndex,
	parent,
	children,
	disabled,
	onStickyTopChanged,
	height,
	scrollHeight,
	hideOnScrollDown: _hideOnScrollDown,
	overlap,
	upTolerance,
	...props
}) => {
	const [mode, setMode] = useState(STATIC)
	const [transition, setTransition] = useState(NO_TRANSITION)
	const [animateUpFrom, setAnimateUpFrom] = useState(null)

	const { layout: { headerType } = {} } = usePageContext()

	// keep in sync with src/components/ui/organisms/header/header.override.js
	const hideOnScrollDown = headerType === 'custom_1' || headerType === 'custom_2' ? false : _hideOnScrollDown

	/** the very last scrollTop which we know about (to determine direction changes) */
	const lastKnownScrollTop = useRef(0)

	const getParent = useCallback(() => parent ?? window.document.documentElement, [parent])

	/**
	 * @returns {number} the current scrollTop position of the window
	 */
	const getScrollTop = useCallback(() => {
		const currentParent = getParent()
		if (currentParent && currentParent.scrollTop !== undefined && currentParent !== document.documentElement) {
			return currentParent.scrollTop
		}
		if (currentParent !== document.documentElement) {
			console.warn('Could not find parent for StickyHeadroom. Defaulting to window, documentElement or body.')
		}
		if (window.pageYOffset !== undefined) {
			return window.pageYOffset
		} else if (window.scrollTop !== undefined) {
			return window.scrollTop
		} else if (document.documentElement) {
			return document.documentElement.scrollTop
		} else if (document.body) {
			return document.body.scrollTop
		} else {
			throw new Error('Could not determine scrollTop!')
		}
	}, [getParent])

	/**
	 * If we're already static and pinStart + scrollHeight >= scrollTop, then we should stay static.
	 * If we're not already static, then we should set the header static, only when pinStart >= scrollTop (regardless of
	 * scrollHeight, so the header doesn't jump up, when scrolling upwards to the trigger).
	 * Else we shouldn't set it static.
	 * @param scrollTop the currentScrollTop position
	 * @param direction the current direction
	 * @returns {boolean} if we should set the header static
	 */
	const shouldSetStatic = useCallback(
		(scrollTop, direction) => {
			if (mode === STATIC || (mode === PINNED && direction === DOWNWARDS)) {
				return pinStart + scrollHeight >= scrollTop
			} else {
				return pinStart >= scrollTop
			}
		},
		[mode, pinStart, scrollHeight]
	)

	/**
	 * Determines the mode depending on the scrollTop position and the current direction
	 * @param {number} scrollTop
	 * @param {string} direction
	 * @returns {string} the next mode of Headroom
	 */
	const determineMode = useCallback(
		(scrollTop, direction) => {
			if (shouldSetStatic(scrollTop, direction)) {
				return STATIC
			} else {
				return direction === UPWARDS ? PINNED : UNPINNED
			}
		},
		[shouldSetStatic]
	)

	/**
	 * @returns {string} determines the kind of transition
	 */
	const determineTransition = useCallback(
		(mode, direction) => {
			// Handle special case: If we're pinned and going to static, we need a special transition using css animation
			if (mode === PINNED && mode === STATIC) {
				return PINNED_TO_STATIC
			}
			// If mode is static, then no transition, because we're already in the right spot
			// (and want to change transform and top properties seamlessly)
			if (mode === STATIC) {
				return transition === NO_TRANSITION ? NO_TRANSITION : PINNED_TO_STATIC
			}
			// mode is not static, transition when moving upwards or when we've lastly did the transition
			return direction === UPWARDS || transition === NORMAL_TRANSITION ? NORMAL_TRANSITION : NO_TRANSITION
		},
		[transition]
	)

	const calcStickyTop = useCallback((mode, height, scrollHeight, hideOnScrollDown) => {
		return mode === PINNED || mode === STATIC || !hideOnScrollDown ? height : height - scrollHeight
	}, [])

	useEffect(() => {
		if (onStickyTopChanged && height) {
			onStickyTopChanged(calcStickyTop(mode, height, scrollHeight, hideOnScrollDown))
		}
	}, [scrollHeight, height, onStickyTopChanged, hideOnScrollDown, calcStickyTop, mode])

	/**
	 * Checks the current scrollTop position and updates the state accordingly
	 */
	const update = useCallback(() => {
		const currentScrollTop = getScrollTop()

		const distanceScrolled = Math.abs(currentScrollTop - lastKnownScrollTop.current)
		const direction = lastKnownScrollTop.current < currentScrollTop ? DOWNWARDS : UPWARDS

		if (
			currentScrollTop === lastKnownScrollTop.current ||
			(hideOnScrollDown && direction === UPWARDS && currentScrollTop > 0 && distanceScrolled < upTolerance)
		) {
			return
		}
		const newMode = determineMode(currentScrollTop, direction)
		const newTransition = determineTransition(newMode, direction)
		let newAnimateUpFrom

		if (mode === PINNED && newMode === STATIC) {
			// animation in the special case from pinned to static
			newAnimateUpFrom = currentScrollTop - pinStart
		}

		setMode(newMode)
		setTransition(newTransition)
		if (newAnimateUpFrom) {
			setAnimateUpFrom(newAnimateUpFrom)
		}
		lastKnownScrollTop.current = currentScrollTop
	}, [determineMode, determineTransition, getScrollTop, mode, pinStart, upTolerance, hideOnScrollDown])

	const handleEvent = useCallback(() => {
		window.requestAnimationFrame(update)
	}, [update])

	const addScrollListener = useCallback(
		(parent) => {
			if (parent === window.document.documentElement) {
				window.addEventListener('scroll', handleEvent)
			} else if (parent) {
				parent.addEventListener('scroll', handleEvent)
			} else {
				console.debug('Property parent of Headroom is null. Assuming, parent will be set soon...')
			}
		},
		[handleEvent]
	)

	const removeScrollListener = useCallback(
		(parent) => {
			if (parent === window.document.documentElement) {
				window.removeEventListener('scroll', handleEvent)
			} else if (parent) {
				parent.removeEventListener('scroll', handleEvent)
			}
		},
		[handleEvent]
	)

	useEffect(() => {
		const currentParent = getParent()
		if (!disabled) addScrollListener(currentParent)

		return () => {
			if (!disabled) removeScrollListener(currentParent)
		}
	}, [addScrollListener, removeScrollListener, disabled, hideOnScrollDown, getParent])

	const translateY = mode === UNPINNED && hideOnScrollDown ? -scrollHeight : 0

	return (
		<Root
			hasScrollHeight={!!scrollHeight}
			translateY={translateY}
			mode={mode}
			transition={transition}
			disabled={disabled}
			animateUpFrom={animateUpFrom}
			zIndex={zIndex}
			overlap={headerType === 'custom_1' || headerType === 'custom_2' || overlap}
			hideOnScrollDown={hideOnScrollDown}
			color={
				headerType === 'custom_1' ? 'var(--color-primary)' : headerType === 'custom_2' ? 'var(--color-secondary)' : ''
			}
			{...props}
		>
			{children}
		</Root>
	)
}

StickyHeadroom.defaultProps = {
	pinStart: 0,
	zIndex: 11,
	hideOnScrollDown: true,
	upTolerance: 0,
}

StickyHeadroom.propTypes = {
	/** The child node to be displayed as a header */
	children: node,
	/** The maximum amount of px the header should move up when scrolling */
	scrollHeight: number,
	/** The minimum scrollTop position where the transform should start */
	pinStart: number,
	/** Used for calculating the stickyTop position of an ancestor */
	height: number,
	/** Fired, when StickyHeadroom changes its state. Passes stickyTop of the ancestor. */
	onStickyTopChanged: func,
	/** True, if sticky position should be disabled (e.g. for edge 16 support) */
	positionStickyDisabled: bool,
	/** The parent element firing the scroll event. Defaults to document.documentElement */
	parent: object,
	/** The z-index used by the wrapper. Defaults to 1. */
	zIndex: number,
}

export default StickyHeadroom
